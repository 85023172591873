<template>
  <div class="verified-card">
    <div v-if="loading">
      <content-loading type="product" />
    </div>
    <div v-else class="apparea">
      <!----- header area start ----->
      <header-desktop />
      <!----- header area stop ----->
      <div v-if="variant != 'success'" class="mb-3 container mt-5">
        <b-card-group deck>
          <b-card
            :border-variant="variantClass"
            :header-bg-variant="variantClass"
            :body-text-variant="variantClass"
            align="right"
          >
            <b-card-text :dir="lang == 'he' ? 'rtl' : 'ltr'">{{ message }}</b-card-text>

            <!-- <div class="row">
              <div class="col-12 col-md-6">
                <b-card-text><a :href="bookingInfoPageLink" target="_blank">{{$t('booking.error-link-message')}}</a></b-card-text>
              </div>
              <div class="col-12 col-md-6">
                <b-button variant="outline-primary" :disabled="orderNo==='N/A'"><a :href="`/check-booking`" target="_blank">Show booking information</a></b-button>
              </div>
            </div> -->
            <b-overlay :show="sendingMessage" rounded="sm" class="d-inline-block" spinner-variant="primary">
              <b-button variant="primary" v-if="isOdysseySite" :disabled="sendingMessage || successfullySentMessage" @click="sendMessage">{{$t("booking.send-message-button")}}</b-button>
            </b-overlay>
          </b-card>
        </b-card-group>
        <button class="btn btn-primary" @click="goToDebit" v-if="allowRetry">
          {{ $t('booking.try-again-debit') }}
        </button>
      </div>

      <payer-confirm-app :payer="payer" />

      <hotel-confirm-app :bookedHotel="bookedHotel" v-if="stateBooking == 'FH'" />

      <div class="please_mall_area order-area card kremlin-area" >
        <div class="plase_mall_contain_box kremlin-list">
          <h2>
            <span>{{ $t('booking.please-note') }}:</span>
            {{ $t('booking.attached-mail') }}
          </h2>
          <ul class="d-flex please_mall_contain_body" v-if="showLink || this.devMode">
            <li class="please_mall" v-if="pdfUrlReceipt || this.devMode">
              <div>
                <div class="icon_box">
                  <b-link
                    :href="pdfUrlReceipt"
                    @click.prevent="
                      downloadTickets(pdfUrlReceipt, 'Receipt')
                    "
                    >
                    <img :src="`${speedSizeDomain}/assets/img/after-booking-icon14.png`" class="col-10" width="100" height="100" alt="after-booking"/>
                  </b-link>
                </div>
                <h5>{{ $t('booking.receipt') }}</h5>
              </div>
            </li>
            <li class="please_mall" v-if="pdfUrlTerms || this.devMode">
              <div>
                <div class="icon_box">
                  <b-link
                    :href="pdfUrlReceipt"
                    @click.prevent="
                      downloadTickets(pdfUrlTerms, 'pdfUrlTerms')
                    "
                    >
                    <img :src="`${speedSizeDomain}/assets/img/after-booking-icon15.png`" width="100" height="100" alt="after-booking"/></b-link>
                </div>
                <h5>{{ $t('booking.general-terms') }}</h5>
              </div>
            </li>
            <li class="please_mall" v-if="pdfUrlItinerary || this.devMode">
              <div>
                <div class="icon_box">
                  <b-link
                    :href="pdfUrlItinerary"
                    @click.prevent="
                      downloadTickets(pdfUrlItinerary, 'Itinerary')
                    "
                    ><img :src="`${speedSizeDomain}/assets/img/after-booking-icon16.png`" width="100" height="100" alt="after-booking"
                  /></b-link>
                </div>
                <h5>{{ $t('booking.itinerary') }}</h5>
              </div>
            </li>
            <li class="please_mall" v-if="pdfUrlTicket || this.devMode">
              <div>
                <div class="icon_box">
                  <b-link
                    :href="pdfUrlTicket"
                    @click.prevent="downloadTickets(pdfUrlTicket, 'Ticket')"
                    ><img :src="`${speedSizeDomain}/assets/img/after-booking-icon17.png`" width="100" height="100" alt="after-booking"/>
                  </b-link>
                </div>
                <h5>{{ $t('booking.flight-tickets') }}</h5>
              </div>
            </li>
            <li class="please_mall" v-if="pdfUrlHotelVoucher || this.devMode">
              <div>
                <div class="icon_box">
                  <b-link
                    :href="pdfUrlHotelVoucher"
                    @click.prevent="
                      downloadTickets(pdfUrlHotelVoucher, 'HotelVoucher')
                    "
                    ><img :src="`${speedSizeDomain}/assets/img/after-booking-icon18.png`" width="100" height="100" alt="after-booking"
                  /></b-link>
                </div>
                <h5>{{ $t('booking.hotel-voucher') }}</h5>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <flight-confirm-app :flights="flights" />
    </div>

    <footer-desktop />
    <!-- <b-toast
      id="waiting"
      :autoHideDelay="waitingTime"
      solid
      :class="[lang == 'he' ? 'rtl' : 'ltr']"
    >
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <h3>{{ $t('booking.waiting') }}</h3>
        </div>
      </template>
      <div class="d-flex">
        <h5>{{ $t('booking.waiting-populating') }}</h5>
        <img :src="`${speedSizeDomain}/assets/img/waiting.gif`" alt="waiting"/>
      </div>
    </b-toast> -->
  </div>
</template>

<script>
import { BCardGroup, BCard, BCardText, BButton, BOverlay, BLink } from 'bootstrap-vue';
import TemplateCardVerifiedApp from './TemplateCardVerifiedApp';
export default {
  components: {
    BCardGroup,
    BCard,
    BCardText,
    BButton,
    BOverlay,
    BLink,
    HeaderDesktop: () => import('@/components/home/HeaderDesktop'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    PayerConfirmApp: () => import('@/components/booking/atoms/PayerConfirmApp'),

    HotelConfirmApp: () => import('@/components/booking/atoms/HotelConfirmApp'),
    FlightConfirmApp: () => import('@/components/booking/atoms/FlightConfirmApp'),
    FooterDesktop: () => import('@/components/home/FooterDesktop'),
  },
  extends: TemplateCardVerifiedApp,
};
</script>

<style>
#waiting.ltr {
  direction: ltr;
}
#waiting.rtl {
  direction: rtl;
}
#waiting h5 {
  padding: 15px 5px;
  font-size: 15px;
}
#waiting img {
  width: 100px;
}
#waiting h3 {
  font-size: 22px;
  font-weight: 600;
  color: #313131;
  text-align: center;
}
</style>

<style scoped>

.card-deck .card {
  margin-bottom: 25px;
}
button.backDebit {
  background: #0061ab;
  color: #fff;
  margin: 10px 10px;
}

h1 {
  font-size: 45px;
  font-weight: 400;
  color: #0e436b;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.after_booking_area .order_no_box {
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 20px;
  margin-bottom: 25px;
}
.after_booking_area .order_no_box .order_no_div {
  width: 100%;
  text-align: left;
}
.after_booking_area .order_no_box .order_no_div h2 {
  margin: 0 0;
  padding: 6px 0;
  color: #fff;
  font-size: 26px;
  font-weight: 400;
  text-transform: uppercase;
}
.after_booking_area .order_no_box .order_no_div h2 strong.order_no {
  color: #79bff5;
}
.after_booking_area .order_no_box .order_no_div p {
  margin: 0 0;
  color: #fff;
  font-size: 15px;
}
.after_booking_area .order_no_box .order_no_div p a {
  text-decoration: underline;
  color: #fff;
  transition: 0.5s;
}
.after_booking_area .order_no_box .order_no_div p a:hover {
  text-decoration: none;
}

.please_mall_area {
  margin-bottom: 25px;
}
.please_mall_area .plase_mall_contain_box {
  border: 1px solid #949494;
}
.please_mall_area .plase_mall_contain_box h3 {
  padding: 20px 20px;
  border-bottom: 1px solid #949494;
  font-size: 24px;
  text-transform: uppercase;
}
.please_mall_area .plase_mall_contain_box h3 span {
  color: #0763ab;
}
.please_mall_area .please_mall_contain_body {
  padding: 30px 20px;
}
.please_mall_contain_body .please_mall {
  width: 100%;
  text-align: center;
}
.please_mall_contain_body .please_mall .icon_box {
  width: 88px;
  height: 82px;
  border: 1px solid #0763ab;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto 0px;
  padding-top: 15px;
}
.please_mall_contain_body .please_mall h5 {
  text-transform: uppercase;
  color: #000;
  margin: 0 0;
  font-size: 16px;
  padding-bottom: 10px;
}
.please_mall_area .img_box {
  height: 275px;
  overflow: hidden;
  border-radius: 5px;
}

#btnSubmit {
  color: #fff;
  background-color: #0061ab;
  border-color: #0061ab;
  font-size: 25px;
  font-weight: bold;
}

 .bonauf-mobile .after_booking_area .order_no_box {
    width: 100%;
    height: auto;
    background-position: right;
    padding: 5px 20px;
    margin-bottom: 15px;
  }
  .bonauf-mobile .order_no_box .d-flex {
    display: block !important;
  }
  .bonauf-mobile .after_booking_area .order_no_box .order_no_div p {
    font-size: 12px;
  }
  .bonauf-mobile .after_booking_area .order_no_box .order_no_div h2 {
    font-size: 16px;
  }
  .bonauf-mobile .please_mall_area .img_box {
    height: auto;
  }
  .bonauf-mobile .please_mall_area .plase_mall_contain_box h3 {
    padding: 14px 13px;
    font-size: 15px;
    text-transform: uppercase;
    text-align: center;
  }
  .bonauf-mobile .please_mall_area .please_mall_contain_body {
    padding: 0px 10px 15px;
  }
  .bonauf-mobile .please_mall_area .please_mall_contain_body .d-flex {
    display: block !important;
  }
  .bonauf-mobile .please_mall_area .please_mall_contain_body .please_mall {
    margin-top: 15px;
  }
  .bonauf-mobile .please_mall_area .please_mall_contain_body .please_mall {
    width: 50%;
    /* text-align: center;
    box-shadow: 2px 3px 2px 2px rgb(0 0 0 / 6%);
    border-radius: 5px; */
  }

  .bonauf-mobile .please_mall_area .please_mall_contain_body .please_mall > div{
    text-align: center;
    box-shadow: 3px 5px 2px 1px rgb(0 0 0 / 20%);
    border-radius: 10px;
  }

  .bonauf-mobile .apparea .card-deck .border-success {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    background-clip: padding-box;
    border: 1px solid;
    border-image: linear-gradient(45deg, #fb6d2f, #f9ff00) 1;
    padding: 12px;
    box-shadow: 0 0 12px rgb(169 169 169 / 60%);
    background-color: #ffffff;
    margin-bottom: 20px;
  }
  .bonauf-mobile .plase_mall_contain_box h2 {
    background: linear-gradient(90deg, #0293d0 0%, rgba(132,219,255,1) 100%);
    padding: 15px;
    box-sizing: border-box;
    color: #ffffff;
    font-family: 'FbCoherentiSansBold';
    font-size: 20px;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .bonauf-mobile .please_mall_area.kremlin-area .kremlin-list ul li{
    width: 50% !important;
    max-width: 50%;
    float: right;
    padding: 5px 30px;
  }
  .bonauf-mobile .please_mall .icon_box img {
    padding: 15px;
  }
  .bonauf-mobile .please_mall_area.kremlin-area .please_mall_contain_body .please_mall .icon_box{
    border: unset;
  }

  .bonauf-mobile .please_mall_area.order-area.card.kremlin-area{
    border: unset;
  }
  .bonauf-mobile .please_mall_area .plase_mall_contain_box.kremlin-list{
    border: unset;
  }

@media (min-width: 991.98px) and (max-width: 1200px) {
  .please_mall_contain_body .please_mall .icon_box {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 1200px) {
  .please_mall_area .img_box img {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .after_booking_area .order_no_box {
    width: 100%;
    height: auto;
    background-position: right;
    padding: 5px 20px;
    margin-bottom: 15px;
  }
  .order_no_box .d-flex {
    display: block !important;
  }
  .after_booking_area .order_no_box .order_no_div p {
    font-size: 12px;
  }
  .after_booking_area .order_no_box .order_no_div h2 {
    font-size: 16px;
  }
  .please_mall_area .img_box {
    height: auto;
  }
  .please_mall_area .plase_mall_contain_box h3 {
    padding: 14px 13px;
    font-size: 15px;
    text-transform: uppercase;
    text-align: center;
  }
  .please_mall_area .please_mall_contain_body {
    padding: 0px 10px 15px;
  }
  .please_mall_area .please_mall_contain_body .d-flex {
    display: block !important;
  }
  .please_mall_area .please_mall_contain_body .please_mall {
    margin-top: 15px;
  }
  .please_mall_area .please_mall_contain_body .please_mall {
    width: 50%;
    /* text-align: center;
    box-shadow: 2px 3px 2px 2px rgb(0 0 0 / 6%);
    border-radius: 5px; */
  }

  .please_mall_area .please_mall_contain_body .please_mall > div{
    text-align: center;
    box-shadow: 3px 5px 2px 1px rgb(0 0 0 / 20%);
    border-radius: 10px;
  }

  .apparea .card-deck .border-success {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    background-clip: padding-box;
    border: 1px solid;
    border-image: linear-gradient(45deg, #fb6d2f, #f9ff00) 1;
    padding: 12px;
    box-shadow: 0 0 12px rgb(169 169 169 / 60%);
    background-color: #ffffff;
    margin-bottom: 20px;
  }
  .plase_mall_contain_box h2 {
    background: linear-gradient(90deg, #0293d0 0%, rgba(132,219,255,1) 100%);
    padding: 15px;
    box-sizing: border-box;
    color: #ffffff;
    font-family: 'FbCoherentiSansBold';
    font-size: 20px;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .please_mall_area.kremlin-area .kremlin-list ul li{
    width: 50% !important;
    max-width: 50%;
    float: right;
    padding: 5px 30px;
  }
  .please_mall .icon_box img {
    padding: 15px;
  }
  .please_mall_area.kremlin-area .please_mall_contain_body .please_mall .icon_box{
    border: unset;
  }

  .please_mall_area.order-area.card.kremlin-area{
    border: unset;
  }
  .please_mall_area .plase_mall_contain_box.kremlin-list{
    border: unset;
  }
}
</style>

<style lang="less" scoped>
  .bonauf-desktop {
    .apparea {
      max-width: 100%;

      & > div {
        max-width: 780px;
        margin: auto;
      }

      .please_mall_area {
        .please_mall_contain_body {
          .icon_box {
            padding: 10px;
          }
        }
      }

    }
  }
</style>
